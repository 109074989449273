(function ($, root, undefined) {

	$(function () {

		'use strict';

		$(document).ready(function() {

			var overlay = $('.js--overlay-clickable');
			var menu = $('.header-links-mobile');
			var hambIcon = $('#hamb-icon');

			// scroll to section
			$('.js--scroll-link').on('click', function(e){
				e.preventDefault();
				var scrollTo = $(this).attr('href');
				var offsetTop = $(scrollTo).offset().top;
				var speed = 1500 - offsetTop;
				if(speed > 1000) {
					speed = 500;
				}
				if(speed <= 0) {
					speed = 1500;
				}
				$('html, body').animate({
					scrollTop: offsetTop
				}, speed);

				if( menu.hasClass('open') ) {
					menu.removeClass('open');
					hambIcon.removeClass('open');
					overlay.removeClass('show');
				}
			});

			/*
			open mobile nav on click
			*/
			hambIcon.on('click', function(){
				if( menu.hasClass('open') ) {
					menu.removeClass('open');
					$(this).removeClass('open');
					overlay.removeClass('show');
				} else {
					menu.addClass('open');
					$(this).addClass('open');
					overlay.addClass('show');
				}
			});

			overlay.on('click', function(){
				if( menu.hasClass('open') ) {
					menu.removeClass('open');
					hambIcon.removeClass('open');
					overlay.removeClass('show');
				}
			});


			/*
			* cookie notice
			*/
			var CookieGet = readCookie('ck_confirmed');
			if (CookieGet == 1) {
				$( "#cookie-notice" ).hide();
			}

			function createCookie( name, value, days ) {
				var expires = "";
				if ( days ) {
				  var date = new Date();
				  date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
				  expires = "; expires=" + date.toGMTString();
				}
	  
				document.cookie = name + "=" + value + expires + "; path=/";
			  }
	  
			  function readCookie( name ) {
				var nameEQ = name + "=";
				var ca = document.cookie.split( ';' );
				for ( var i = 0; i < ca.length; i++ ) {
				  var c = ca[ i ];
				  while ( c.charAt( 0 ) == ' ' ) c = c.substring( 1, c.length );
				  if ( c.indexOf( nameEQ ) === 0 ) return c.substring( nameEQ.length, c.length );
				}
				return null;
			  }
	  
			  function eraseCookie( name ) {
				createCookie( name, "", -1 );
			  }
	  
	  
			  $('#cookie-accept').on('click', function() {
					createCookie( "ck_confirmed", "1", 90 );
					$( "#cookie-notice" ).addClass( "is-hidden" );
					return false;
				});
				
				// cookie info for mobile
				
				var addInfoBtn = $('#js--more-info-cookies');
				var addInfo = $('#js--cookie-info');
				addInfoBtn.on('click', function(e){
					e.preventDefault();
					if(!addInfo.hasClass('show')) {
						addInfo.addClass('show');
					}
					$(this).hide();
				})


		});

	});

})(jQuery, this);

